import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import TestimonialSlider from "../components/sliders/sap-testimonial-slider/sap-testimonial-slider"
import BackgroundImage from "gatsby-background-image"
import PatnersSlider from "../components/sliders/patners-slider/patners-slider"

const About = ({ data }) => {
  const images = {
    aboutbg: convertToBgImage(getImage(data.aboutbg)),
    Footprint: convertToBgImage(getImage(data.Footprint)),
    what_client_says: convertToBgImage(getImage(data.what_client_says)),
  }

  const personImages = {
    Deeb: getImage(data.Deeb),
    Hafez: getImage(data.Hafez),
    Lalindra: getImage(data.Lalindra),
    Shyamalan: getImage(data.Shyamalan),
  }

  const patnersImages = {
    Neovatic: getImage(data.Neovatic),
    mi: getImage(data.mi),
    clock_work: getImage(data.clock_work),
    blue_print: getImage(data.blue_print),
  }

  const testimonialsImages = {
    mangala: getImage(data.mangala),
    gerard: getImage(data.gerard),
    lalith: getImage(data.lalith),
    samuel: getImage(data.samuel),
    viraj: getImage(data.viraj),
    minhaj: getImage(data.minhaj),
    gayan: getImage(data.gayan),
    vidunath: getImage(data.vidunath),
  }

  const logos = {
    Sap_s4: getImage(data.Sap_s4),
    Sap_businessbydesign: getImage(data.Sap_businessbydesign),
    Vertiv: getImage(data.Vertiv),
  }

  const testimonial_slider = [
    {
      index: 0,
      logo: logos.Sap_s4,
      name: "Mangala Wickramasinghe",
      position: "Group Chief Information Officer",
      company: "Diesel & motor Engineering PLC",
      image: testimonialsImages.mangala,
      description:
        "As a Group, we transitioned into industries including Medical Equipment and Engineering while our existing systems and processes were primarily aligned to the Automotive industry. This was the main reason behind our need for a tier one ERP solution such as SAP, that could function across industries. We selected Tech Pacific after much consideration, and still remain grateful for the choice. They were outstanding in terms of understanding our requirements and supporting us in translating that into ERP solutions. Tech Pacific exceeded all expectations in visualising the digital transformation for the entire group",
    },
    {
      index: 1,
      logo: logos.Sap_businessbydesign,
      name: "Gerard Suares",
      position: "Chief Financial Officer",
      company: "W.K.V. Hydro Technics (Pvt) Ltd",
      image: testimonialsImages.gerard,
      description:
        "Our association with Tech Pacific dates back to their inception in 2008. Seldom does one find a partner who brings a precise vision to life; and Tech pacific has been consistent in this regard. They exceeded our expectations by carrying out the company’s digital transformation with seamless expertise, professionalism and timely delivery. It has been a pleasant journey, and we strongly recommend Tech Pacific to any business embarking on the progressive journey of digital transformation.",
    },
    {
      index: 2,
      logo: logos.Sap_s4,
      name: "Lalith Kulasinghe",
      position: "Chief Information Officer",
      company: "Colombo Fort Group Service PVT LTD",
      image: testimonialsImages.lalith,
      description:
        "Tech Pacific carried out the implementation of SAP S/4HANA ERP system for two of our companies; namely E.B Creasy Group and C.W Mackie group, both subsidiaries of Colombo Fort Land and Building PLC. A significant change that enabled both companies to have a centralized system for business operations back in 2016. We are currently working with the same team on upgrading to the latest S/4HANA 2020, with embedded FIORI for the next-generation workforce. Having worked with Tech pacific for half a decade, we have witnessed their technical competencies and customer oriented business strategy first hand. This has helped establish a partnership that goes beyond business, and we have no hesitation in recommending Tech Pacific.",
    },
    {
      index: 3,
      logo: logos.Vertiv,
      image: testimonialsImages.samuel,
      name: "Samuel Sathiyadaran",
      position: "Information Technology Consultant",
      company: "Alliance Finance Company PLC",
      description:
        "Our association with Tech Pacific started back in 2016, when we required maintenance for our Blade Center RACK. Since then, Tech pacific has carried out our maintenance with superior quality along with technical assistance offered at request. Their high standards in professionalism, quality, consistent on time delivery, and willingness to negotiate renewals is the reason behind Alliance Finance selecting Tech Pacific time and again.",
    },
    {
      index: 4,
      logo: logos.Vertiv,
      image: testimonialsImages.vidunath,
      name: "Vidunath Mathagaweera",
      position: "Assistant Manager",
      company: "FairFirst Insurance Ltd",
      description:
        "We chose Tech Pacific to be our partner on D/Emerson Smart Rack Servicing and maintenance in 2018, and working with their team has been quite the experience. They have an extremely professional technical team, consisting of highly skilled workers. Their attention to detail and on-time delivery have consistently stood out, and we will continue to lean on Tech Pacific for their services.",
    },
    {
      index: 2,
      logo: logos.Vertiv,
      image: testimonialsImages.viraj,
      name: "Viraj Liyanage",
      position: "Manager Systems Development",
      company: "MAGA ENGINEERING PVT LTD",
      description:
        "Tech Pacific was responsible for the successful installation of our Data Center Infrastructure back in 2015. What truly stood out to us was the support offered even after providing the solution. We would highly recommend Techpac for their superior products, after sales support and professional staff; having access to all levels of management when required was an unexpected value addition.",
    },
    {
      index: 5,
      logo: logos.Vertiv,
      image: testimonialsImages.minhaj,
      name: "Minhaj Hussain",
      position: "IT Manager",
      company: "Lakjaya Micro Finance LTD",
      description:
        "Our relationship with Tech Pacific began in 2018, with the installation of a Network Smartrack Solution. We were pleasantly surprised by their team of specialists equipped with strong technical expertise. Tech Pacific’s timely responsiveness in providing solutions to our problems was a pleasure only surpassed by their professionalism in customer care.",
    },
    {
      index: 6,
      logo: logos.Vertiv,
      image: testimonialsImages.gayan,
      name: "Gayan Perera",
      position: "Deputy Manager IT",
      company: "Siyapatha Finance PLC",
      description:
        "We began our journey with Tech Pacific back in 2016 when purchasing two Emerson Smart Server Cabinets. Our experience over five years has been consistently supportive.Mr. Farzan, Mr. Sasanka, Mr. Chanaka, and the entire team have surpassed our initial expectations. The after-sales service is unparalleled because they attend to breakdowns without delay irrespective of day and night. We would highly recommend Tech Pacific.",
    },
  ]

  const patners = [
    {
      index: 0,
      img: patnersImages.Neovatic,
      title: "NEOVATIC TECHNOLOGIES PVT LTD",
      subTitle: "Exclusive Partner for SAP S/4HANA Implementations",
      description:
        "Neovatic is an innovation driven, digital solutions and technology services company that harnesses the power of digital transformation and hyper automation to provide strategic advantage, improved business processes and enhanced organizational performance with proven track record of customer success spanning across three continents. Neovatic has diverse customer base in industries such as Manufacturing, Consumer products, Professional services, Metals and Mining, Retail, Chemical, Pharma, BFSI and Health Care. Headquartered in Hyderabad, India, Neovatic is strengthened by a team of leaders with extensive global experience in domain, varied landscape of technologies and track record of delivering complex and large scale solutions. Neovatic has created a digital transformation framework based on the experience with numerous clients across multiple domains to help achieve desired business outcomes in a true transformational manner. Neovatic’s Core value is “Customer First”. Neovatic's success is measured based on their customer's business outcomes and they achieve this by leveraging their unique blend of IT expertise and best practices.",
      link: "https://www.neovatic.com/",
      linkText: "www.neovatic.com",
    },
    {
      index: 1,
      img: patnersImages.mi,
      title: "MUKESH INFOSERVE PVT LTD",
      subTitle:
        "Exclusive Partner for SAP Business ByDesign (SME Cloud ERP) Implementations",
      description:
        "Mukesh Infoserve is a 22 year old company with more than 150+ customers in SAP across multiple verticals and geographies. They provide their customers, SAP business software  solutions to seamlessly integrate all business functions to enable them to make business decisions. They help their customers save operation costs, providing amazing technology, ensuring smooth delivery at the right price.",
      link: "https://www.mukeshinfoserve.com/",
      linkText: "www.mukeshinfoserve.com",
    },
    {
      index: 2,
      img: patnersImages.clock_work,
      title: "CLOCKWORK BUSINESS SOLUTION PVT LTD",
      subTitle:
        "Exclusive Partner for SAP Business One (SME ERP) Implementations",
      description:
        "Clockwork Business Solutions Pvt Ltd. is a Gold certified SAP partner headquartered in Bengaluru, India. Clockwork has to its credit more than 300 SAP Business One implementations, executed in India and overseas and across industry verticals. Clockwork delivers solutions/services across wide spectrum viz., ERP, Data Analytics, Web and Mobile Applications and truly acts as a value adding partner throughout the solution lifecycle. Clockwork, spearheaded by globally educated & experienced professionals and complemented by hands-own product experts, offers cutting-edge transformative solutions to client organizations.",
      link: "https://www.clockwork.in/",
      linkText: "www.clockwork.in",
    },
    {
      index: 3,
      img: patnersImages.blue_print,
      title: "BLUEPRINT TECHNOLOGIES PVT LTD",
      subTitle:
        "Exclusive Partner for SAP SuccessFactors (Cloud HCM) Implementations",
      description:
        "Blueprint Technologies is a niche SAP services company established in 2010 to help their clients of all sizes to be successful on their digital transformation journeys. They are SAP Gold Partners for On-Premise and Cloud SAP solutions with expertise across SAP SuccessFactors, SAP Concur, SAP C/4HANA, S/4HANA, BW4/HANA, Kronos and are building cutting edge next-gen solutions leveraging SAP Intelligent Technologies like iRPA, AI, ML solutions. They have won “SAP Cloud Impact Partner of the year 2020”, Finalists in SAP Pinnacle Awards 2021 (GLOBAL AWARD) and HR Cloud Partner of the Year. Two time winners of SAP ACE awards & Kronos Value Partner of the Year awards.",
      link: "https://www.bpterp.com/",
      linkText: "www.bpterp.com",
    },
  ]

  return (
    <div>
      <Layout>
        <Seo title="About | Tech Pacific Lanka" />
        <section>
          <BackgroundImage {...images.aboutbg} className="main">
            <div
              // fluid={images.sapbBg}
              className="sap-header common-header"
            >
              <div className="container">
                <div className="sap-header-content common-header-content">
                  <div className="about-header">About Us</div>
                  <div className="header-description">
                    Tech Pacific is a testament to the pace of digital growth,
                    having evolved with changing technology since its inception.
                    Today it is a leading enterprise backed by a diverse
                    workforce, specializing in Digital Transformation, Digital
                    Infrastructure and Applications.
                  </div>
                  <div className="header-buttons d-flex">
                    <Link to="/contact">
                      <button>Connect</button>
                    </Link>
                    <a href="tel:1234567890">
                      <button>Call Now</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
        </section>
        <section>
          <div className="about-mid-section">
            <div className="container">
              <div className="about-mid-section-content d-flex text-center flex-column">
                <span className="about-vision">Our Vision</span>
                <span>To Make Technology Simple for Every Business</span>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="about-person">
            <div className="person-raw container">
              <div className="row justify-content-between">
                <div className="col-md-4">
                  <div className="person-content">
                    <GatsbyImage
                      placeholder="blurred"
                      image={personImages.Lalindra}
                      alt="person"
                    />
                    <div className="person-details">
                      <div className="person-title">Lalindra Galagedera</div>
                      <div className="person-position">
                        Chief Executive Officer
                      </div>
                      <div className="person-description">
                        Lalindra’s dynamic skill set in maneuvering both
                        customer and employee relationships has had a strong
                        impact on the company’s growth. His relentless
                        perseverance and long-term vision for Tech Pacific
                        revolves around several core principles: ethics and
                        integrity.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="person-content">
                    <GatsbyImage
                      placeholder="blurred"
                      image={personImages.Deeb}
                      alt="person"
                    />
                    <div className="person-details">
                      <div className="person-title">Deeb Wahid</div>
                      <div className="person-position">Chairman</div>
                      <div className="person-description">
                        A leader who sets the bar high, Deeb Wahid persistently
                        holds up Tech Pacific’s standards of excellence and
                        commitment, ensuring follow-through of planned
                        trajectories and implementation of organizational
                        vision, mission and long-term goals; whilst steering the
                        company to its optimally profitable direction.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="person-raw container">
              <div className="row justify-content-between">
                <div className="col-md-4">
                  <div className="person-content">
                    <GatsbyImage
                      placeholder="blurred"
                      image={personImages.Hafez}
                      alt="person"
                    />
                    <div className="person-details">
                      <div className="person-title">Hafez Wahid</div>
                      <div className="person-position">Managing Director</div>
                      <div className="person-description">
                        A strategist and leader, he supervises the preparation
                        and implementation of comprehensive business plans to
                        facilitate achievement. He also plans market
                        development, and is eager to come up with new offerings
                        to ensure company growth.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="person-content">
                    <GatsbyImage
                      placeholder="blurred"
                      image={personImages.Shyamalan}
                      alt="person"
                    />
                    <div className="person-details">
                      <div className="person-title">Shyamalan Ratnam</div>
                      <div className="person-position">Director - Finance</div>
                      <div className="person-description">
                        Playing an active role at Tech Pacific; he achieves a
                        fine balance between delivering leadership to the
                        finance teams and strategic recommendations to the
                        management teams. His capacity extends to overseeing the
                        preparation of all financial reports while taking the
                        lead on financial and contractual negotiations.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <BackgroundImage
            {...images.what_client_says}
            className="sap-testimonials about-testimonials"
          >
            <div className="container">
              <div className="sap-testimonials-title about-testimonials-title">
                What Clients Say
              </div>
              <Link to="/contact">
                <button>Talk to us</button>
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <div className="swiper-button-prev1"></div>
              <div className="testimonial-super-main">
                <div className="testimonial-item-main">
                  <TestimonialSlider slides={testimonial_slider} />
                </div>
              </div>
              <div className="swiper-button-next1"></div>
            </div>
          </BackgroundImage>
        </section>
        <section>
          <div className="footprints">
            <div className="container">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 fotprints-sectoins para">
                    <div className="footprints-title">Footprints</div>
                    <div className="footprints-content">
                      In keeping with our vision, we have steadily expanded our
                      presence across enterprises both large and small,
                      operating in multiple sectors in Sri Lanka, South Asia and
                      Southeast Asia.
                    </div>
                  </div>
                  <BackgroundImage
                    {...images.Footprint}
                    className="col-md-9 fotprints-sectoins"
                  >
                    {/* <div className="col-md-9 fotprints-sectoins bg"> */}
                    {/* Image goes here */}
                    {/* </div> */}
                  </BackgroundImage>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="patners-slider">
            <div className="patners-content container">Partners</div>
            <div className="d-flex align-items-center">
              <div className="swiper-button-prev1"></div>
              <div className="client-slider-item">
                <PatnersSlider slides={patners} />
              </div>
              <div className="swiper-button-next1"></div>
            </div>
          </div>
        </section>

        <section>
          <div className="get-in-touch blue d-flex align-items-center justify-content-center">
            <div className="get-in-touch-content text-center container">
              <p>Get In Touch With Our Experts</p>
              <Link to="/contact">
                <button
                  className="btn btn-primary blue-contact-footer"
                  type="button"
                >
                  Let's Get to Work
                </button>
              </Link>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default About

export const query = graphql`
  query About {
    aboutbg: file(relativePath: { eq: "about/aboutbg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    what_client_says: file(relativePath: { eq: "about/what_client_says.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Footprint: file(relativePath: { eq: "about/footprint.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Deeb: file(relativePath: { eq: "about/person/Deeb.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Hafez: file(relativePath: { eq: "about/person/Hafez.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Lalindra: file(relativePath: { eq: "about/person/Lalindra.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Shyamalan: file(relativePath: { eq: "about/person/Shyamalan.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Neovatic: file(relativePath: { eq: "about/patner-logos/Neovatic.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    mi: file(relativePath: { eq: "about/patner-logos/mi.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    clock_work: file(
      relativePath: { eq: "about/patner-logos/clock_work.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    blue_print: file(
      relativePath: { eq: "about/patner-logos/blue_print.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    mangala: file(relativePath: { eq: "testimonials/mangala.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lalith: file(relativePath: { eq: "testimonials/lalith.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    gerard: file(relativePath: { eq: "testimonials/gerard.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    vidunath: file(relativePath: { eq: "testimonials/vidunath.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    samuel: file(relativePath: { eq: "testimonials/samuel.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    viraj: file(relativePath: { eq: "testimonials/viraj.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    minhaj: file(relativePath: { eq: "testimonials/minhaj.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    gayan: file(relativePath: { eq: "testimonials/gayan.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    Sap_s4: file(relativePath: { eq: "about/logos/Sap_s4.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    Sap_businessbydesign: file(
      relativePath: { eq: "about/logos/Sap_businessbydesign.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    Vertiv: file(relativePath: { eq: "about/logos/Vertiv.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
