import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
// import { getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import { Link } from "gatsby"

const PatnersSlider = props => {
  return (
    <Swiper
      slidesPerView={2}
      spaceBetween={250}
      slidesPerGroup={1}
      loop={true}
      loopFillGroupWithBlank={true}
      navigation={{
        nextEl: ".swiper-button-next1",
        prevEl: ".swiper-button-prev1",
      }}
      className="mySwiper"
      breakpoints={{
        1400: {
          maxWidth: 1400,
          slidesPerView: 2,
        },
        992: {
          maxWidth: 992,
          slidesPerView: 2,
        },
        768: {
          maxWidth: 768,
          slidesPerView: 1,
        },
        576: {
          maxWidth: 576,
          slidesPerView: 1,
        },
        0: {
          maxWidth: 0,
          slidesPerView: 1,
        },
      }}
    >
      {props.slides.map(slide => {
        return (
          <SwiperSlide key={slide.index} className="patners-res">
            <div className=" d-flex flex-column">
              <span>
                <GatsbyImage
                  placeholder="blurred"
                  image={slide.img}
                  alt="techpac"
                  className="img"
                />
              </span>
              <span className="title patners-title">{slide.title}</span>
              <span className="sub-title">{slide.subTitle}</span>
              <p className="content d-flex flex-column">
                {slide.description}
                {/* <a href={slide.link} rel="noreferrer" target="_blank">
                  {slide.linkText}
                </a> */}
              </p>
              <span>
                <div className="d-flex pt-4 align-items-center gray">
                  <a
                    href={slide.link}
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex"
                  >
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </a>
                  <Link to="/contact" className="patner-button">
                    <div>Connect Now</div>
                  </Link>
                </div>
              </span>
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
export default PatnersSlider
